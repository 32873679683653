import { StyleSheet } from "@react-pdf/renderer";
export default StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    fontSize: 12,
    fontFamily: "Cabin",
    fontStyle: "italic",
  },
  aside: {
    backgroundColor: "#1d1527",
    paddingVertical: 10,
    paddingHorizontal: 20,
    width: 200,
    color: "#ececec",
  },
  photo: {
    height: 170,
    backgroundColor: "#9B6ED0",
    borderTopLeftRadius: "30px",
    borderBottomRightRadius: "30px",
  },
  subtitle: {
    color: "#9B6ED0",
    fontSize: "16px",
    borderBottom: "2px solid #9B6ED0",
    textTransform: "uppercase",
    paddingTop: 8,
    paddingBottom: 8,
    marginBottom: 16,
  },
  title: {
    fontSize: "48px",
  },
  sectionTitle: {
    color: "#9B6ED0",
    marginTop: 15,
    marginBottom: 10,
    fontSize: "16px",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  timeline: {
    borderLeft: "2px solid #9B6ED0",
    paddingBottom: 5,
    paddingHorizontal: 10,
    display: "flex",
    position: "relative",
  },
  timelineTitle: {
    fontSize: "12px",
    color: "#9B6ED0",
    width: "100%",
    textTransform: "uppercase",
    marginTop: 5,
  },
  description: {
    padding: 5,
    lineHeight: 1.5,
  },
  info: {
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
    marginBottom: 10,
    justifyContent: "space-between",
    fontSize: "12px",
  },
  timelineIcon: {
    width: 10,
    height: 10,
    border: "2px solid #9B6ED0",
    borderRadius: "50%",
    position: "absolute",
    left: -6,
    top: 7,
    backgroundColor: "#E4E4E4",
  },
  skills: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  skill: {
    paddingVertical: 3,
    paddingHorizontal: 5,
    margin: 2,
  },
  contact: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});
